<template>
  <zmAppPage :show-footer="true" :show-header="true" :showBack="false" :showHeaderLeft="true" :showLeft="true" class="selectBook" title="投票表决">
    <div class="vote-container cf">
      <template v-if="data">
        <div class="vote-title">{{data.name}}</div>
        <van-radio-group v-model="value">
          <van-radio :key="i" :name="o.value" class="vote-option" v-for="(o, i) in options">{{o.label}}</van-radio>
        </van-radio-group>
      </template>
    </div>
    <div class="page-footer" slot="footer">
      <van-button :disabled="value === undefined" @click="onClickSubmit" class="page-footer__btn" type="info">提交</van-button>
    </div>
  </zmAppPage>
</template>

<script>
import { Toast } from 'vant';
import { getUuid } from '@/utils';
export default {
  name: 'Vote',
  data() {
    return {
      data: null,
      value: undefined,
      loading: false,
      options: [
        { label: '同意', value: 1 },
        { label: '不同意', value: 2 },
        { label: '弃权', value: 0 }
      ]
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    }
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id === undefined) {
        return this.$msg.showMsg('无效的表决信息');
      }
      // this.loading = true;
      this.$apis
        .getVoteDetail(this.id)
        .then((res) => {
          this.data = res;
          // this.loading = false;
        })
        .catch(() => {
          // this.loading = false;
        });
    },
    onClickSubmit() {
      let requestFun;
      if (this.value === 0) {
        requestFun = this.$apis.waiverVote;
      } else if (this.value === 1) {
        requestFun = this.$apis.agreeVote;
      } else if (this.value === 2) {
        requestFun = this.$apis.againstVote;
      }
      requestFun(this.id, { code: getUuid() }).then((res) => {
        Toast({ message: '谢谢参与, 表决已提交', type: 'text', duration: 3500, closeOnClick: true });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.vote-container {
  padding: 50px 20px 20px;
}
.vote-title {
  font-weight: bold;
  margin-bottom: 30px;
}
.vote-option {
  margin-bottom: 20px;
}
.vote-loading {
  padding-top: 20vh;
  text-align: center;
}
.page-footer {
  padding: 10px;
  text-align: center;
  &__btn {
    width: 100%;
  }
}
</style>
